<template>
  <div>
    <survey-header
      :survey="survey"
      :category="category"
      :invitation-link="invitationLink"
    />
    <file-view
      :data="survey.feedback"
    />
    <b-card
      no-body
    >
      <div class="d-flex mt-2 ml-3">
        <app-timeline class="mb-2 ml-3">
          <!-- Survey SET-->
          <app-timeline-item
            v-for="(surveySet,index) in surveySets"
            :id="surveySet.ID.toString()"
            :key="index"
            variant="primary"
            icon="ClockIcon"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-2 mb-sm-0">
              <h6> {{ $t("SET") }} {{ index+1 }} : {{ formatDate(surveySet.start_date) }} - {{ formatDate(surveySet.end_date) }}</h6>
            </div>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-toggle="'survey-list-' + index"
              size="sm"
              class="mt-2"
              variant="outline-primary"
            >
              {{ $t("SHOW_RESULTS") }}
            </b-button>
            <b-collapse :id="'survey-list-' + index">
              <b-row
                v-for="(surveyQuestion, surveyIndex) in getQuestionsBySetId(surveySet.ID)"
                :key="surveyIndex"
              >
                <b-col
                  v-if="surveyQuestion.question.question_type === questionType[1]"
                  cols="12"
                  xl="12"
                  md="12"
                >
                  <survey-open-ended
                    :data="surveyQuestion.question"
                    :is-email-show="true"
                  />
                </b-col>
                <b-col
                  v-if="surveyQuestion.question.question_type === questionType[0]"
                  cols="12"
                  xl="12"
                  md="12"
                >
                  <!-- Check if the question type is 'rating' before rendering survey-chart -->
                  <template>
                    <survey-bar-chart
                      :key="surveyIndex+index"
                      :data="surveyQuestion"
                      :index="surveyIndex+index"
                    />
                  </template>
                </b-col>
                <b-col
                  v-if="surveyQuestion.question.question_type === questionType[2]"
                  cols="12"
                  xl="12"
                  md="12"
                >
                  <!-- Check if the question type is 'rating' before rendering survey-chart -->
                  <template>
                    <survey-pie-chart
                      :key="surveyIndex+index"
                      :index="surveyIndex+index"
                      :data="surveyQuestion"
                    />
                  </template>
                </b-col>
              </b-row>
            </b-collapse>
          </app-timeline-item>
        </app-timeline>
      </div></b-card>
    <!-- Questions -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCollapse,
  BButton,
  VBToggle,
  VBTooltip,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { mapActions, mapGetters } from 'vuex'
import { formatDate, avatarText } from '@core/utils/filter'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'
import { resolveUserRole } from '@/constants/utils'
import SurveyBarChart from './SurveyBarChart.vue'
import SurveyPieChart from './SurveyPieChart.vue'
import SurveyHeader from './SurveyHeader.vue'
import SurveyOpenEnded from './SurveyOpenEnded.vue'
import FileView from './FileView.vue'

export default {
  components: {
    BRow,
    BCol,
    SurveyBarChart,
    SurveyPieChart,
    SurveyHeader,
    SurveyOpenEnded,
    FileView,
    BCard,
    AppTimeline,
    AppTimelineItem,
    BCollapse,
    BButton,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      survey: {
        ID: 0,
        encryptID: '',
        name: '',
        start_date: '',
        end_date: '',
        total_users: 0,
        total_responses: 0,
        average_rating: 0,
        survey_status: '',
        frequency: '',
        sequence: '',
        competition_id: 0,
        company_id: 0,
        category_id: 0,
        description: '',
        feedback: '',
        survey_questions: [],
      },
      category: {},
      invitationLink: '',
      surveySets: [],
      categoryOptions: [],
      selectdQuestions: [],
      selectdCategories: [],
      questionType: constants.QUESTION_TYPE,
      encryptedDefaultId: constants.ENCRYPTED_DEFAULT_ID,
    }
  },
  setup() {
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
      formatDate,
      resolveUserRole,
      avatarText,
    }
  },
  async created() {
    if (router.currentRoute.params.id) {
      // eslint-disable-next-line radix
      this.survey.ID = parseInt(router.currentRoute.params.id)
      if (this.survey.ID !== 0) {
        this.fetchSurveyResultByID(this.survey.ID).then(async response => {
          if (response) {
            this.survey = response.data
            this.setInvitationLink(this.survey)
            await this.fetchCategory()
            this.fetchSets()
            this.setFeedback()
          }
        })
      }
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters('template', ['getAllCategories']),
  },
  methods: {
    ...mapActions('template', ['fetchAllCategories']),
    ...mapActions('survey', [
      'fetchSurveyResultByID',
      'fetchSurveySetByID',
    ]),
    setInvitationLink(data) {
      this.invitationLink = `${process.env.VUE_APP_HOST}bisevo/survey/${data.encryptID}?userKey=${this.encryptedDefaultId}`
      if (data.survey_status === constants.SURVEY_STATUS[1]) {
        this.invitationLink = `${process.env.VUE_APP_HOST}bisevo/survey-results/${data.encryptID}`
      }
    },
    setFeedback() {
      if (this.survey.feedback) {
        this.survey.feedback = this.survey.feedback.replace(/\n/g, '<br>')
      }
    },
    async fetchCategory() {
      this.fetchAllCategories().then(response => {
        if (response) {
          this.categoryOptions = this.getAllCategories
          if (this.survey.ID) {
            this.category = this.categoryOptions.find(
              i => i.ID === this.survey.category_id,
            )
          }
        }
      })
    },
    getQuestionsBySetId(setId) {
      const filteredQuestions = this.survey.survey_questions.map(question => {
        const filteredAnswers = question.question.survey_question_answers.filter(answer => answer.set_id === setId)
        return filteredAnswers.length > 0 ? { ...question, question: { ...question.question, survey_question_answers: filteredAnswers } } : null
      }).filter(question => question !== null)
      return JSON.parse(JSON.stringify(filteredQuestions))
    },
    fetchSets() {
      const params = `?survey_id=${this.survey.ID}`
      this.fetchSurveySetByID(params).then(response => {
        if (response) {
          this.surveySets = response.data
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
